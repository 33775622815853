<template>
  <div class="bottom">
      <div class="row">
        <div class="col-12">
            <div class="text-center">&copy; 2023 furimuri</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerPage'
}
</script>
<style scoped>
div.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px; /* 页脚高度 */
	color: #888;
}
</style>
